import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { Card } from '../components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { useToast } from '../hooks/use-toast';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '../components/auth/login-form';
import { RegisterForm } from '../components/auth/register-form';
import { ResetPasswordForm } from '../components/auth/reset-password-form';
import '../styles/gradient.css';

// Preload background image
const bgImage = new Image();
bgImage.src = '/bg-waves.png';

export default function Login() {
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [bgLoaded, setBgLoaded] = useState(false);

  useEffect(() => {
    if (bgImage.complete) {
      setBgLoaded(true);
    } else {
      bgImage.onload = () => setBgLoaded(true);
    }
  }, []);

  if (showResetPassword) {
    return <ResetPasswordForm onBack={() => setShowResetPassword(false)} />;
  }

  return (
    <div className="relative min-h-screen w-screen overflow-hidden bg-black">
      {bgLoaded && (
        <div 
          className="fixed inset-0 w-full h-full bg-[url('/bg-waves.png')] bg-cover bg-center opacity-0 animate-fade-in"
          style={{ 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            animation: 'fadeIn 0.5s ease-in forwards'
          }}
        />
      )}
      <div className="relative z-10 min-h-screen flex items-center justify-center p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md"
        >
          <Card className="p-8 bg-gradient-dark animate-gradient border border-white/5 backdrop-blur-sm rounded-2xl">
            <div className="flex flex-col items-center space-y-6">
              <img 
                src="https://guapexplorer.com/ico/android-icon-192x192.png" 
                alt="GUAP" 
                className="w-20 h-20"
                loading="eager"
                fetchPriority="high"
              />
              <h1 className="text-3xl font-bold text-gradient-gold-white">
                GuapCoin Wallet
              </h1>

              <Tabs defaultValue="login" className="w-full">
                <TabsList className="grid w-full grid-cols-2 mb-6 rounded-xl bg-[#232323]">
                  <TabsTrigger value="login" className="rounded-l-xl data-[state=active]:bg-gradient-gold data-[state=active]:text-black">Login</TabsTrigger>
                  <TabsTrigger value="register" className="rounded-r-xl data-[state=active]:bg-gradient-gold data-[state=active]:text-black">Register</TabsTrigger>
                </TabsList>

                <TabsContent value="login">
                  <LoginForm onForgotPassword={() => setShowResetPassword(true)} />
                </TabsContent>

                <TabsContent value="register">
                  <RegisterForm />
                </TabsContent>
              </Tabs>
            </div>
          </Card>
        </motion.div>
      </div>
    </div>
  );
}
