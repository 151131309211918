import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  LayoutDashboard, 
  Send, 
  Wallet,
  Download, 
  Settings, 
  HelpCircle,
  ExternalLink,
  Menu,
  LogOut,
  User
} from 'lucide-react';
import { useState } from 'react';
import { Button } from './ui/button';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../lib/firebase';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: LayoutDashboard },
  { name: 'Send', href: '/send', icon: Send },
  { name: 'Receive', href: '/receive', icon: Wallet },
  { name: 'Import Wallet', href: '/import-wallet', icon: Download },
  { name: 'Settings', href: '/settings', icon: Settings },
  { name: 'FAQ', href: '/faq', icon: HelpCircle },
];

export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { currentUser, bypassAuth, setBypassAuth, setWalletInfo } = useAuth();

  const handleLogout = async () => {
    if (bypassAuth) {
      setBypassAuth(false);
      setWalletInfo(null);
    } else {
      await auth.signOut();
    }
    navigate('/');
  };

  const username = bypassAuth 
    ? 'Demo User' 
    : currentUser?.email?.split('@')[0] || 'User';

  return (
    <div className="h-screen bg-guap-black flex">
      {/* Sidebar */}
      <aside className={`
        fixed top-0 left-0 h-full w-64 bg-guap-dark z-50
        flex flex-col
        transform transition-transform duration-300 ease-in-out
        lg:translate-x-0 lg:relative
        ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}
      `}>
        <div className="p-4 flex-1 overflow-y-auto">
          <div className="flex items-center gap-2 mb-8">
            <img 
              src="https://guapexplorer.com/ico/android-icon-192x192.png" 
              alt="GUAP" 
              className="w-8 h-8"
            />
            <h1 className="text-xl font-bold text-white">GUAP Wallet</h1>
          </div>

          {/* User Info - Desktop */}
          <div className="hidden lg:flex items-center gap-2 px-4 py-2 mb-4 bg-black/20 rounded-lg">
            <User className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-400">{username}</span>
          </div>

          <nav className="space-y-2">
            {navigation.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={() => setIsMobileMenuOpen(false)}
                  className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                    isActive 
                      ? 'bg-gradient-gold text-black' 
                      : 'text-white hover:bg-white/10'
                  }`}
                >
                  <item.icon className="w-5 h-5" />
                  {item.name}
                </Link>
              );
            })}
          </nav>
        </div>

        <div className="p-4 border-t border-guap-border">
          <Button
            onClick={handleLogout}
            variant="outline"
            className="w-full border-guap-yellow text-white hover:bg-white/10 mb-2"
          >
            <LogOut className="w-4 h-4 mr-2" />
            Logout
          </Button>
          <a
            href="https://www.guapcoin.org"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-2 px-4 py-2 text-white hover:bg-white/10 rounded-lg"
          >
            <ExternalLink className="w-5 h-5" />
            GuapCoin.org
          </a>
        </div>
      </aside>

      {/* Mobile Header */}
      <div className="lg:hidden fixed top-0 left-0 right-0 border-b border-guap-border p-4 bg-guap-black z-40 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <img 
            src="https://guapexplorer.com/ico/android-icon-192x192.png" 
            alt="GUAP" 
            className="w-8 h-8"
          />
          <h1 className="text-xl font-bold text-white">GUAP Wallet</h1>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2 text-gray-400">
            <User className="w-4 h-4" />
            <span className="text-sm hidden sm:inline">{username}</span>
          </div>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-white"
          >
            <Menu className="w-6 h-6" />
          </Button>
        </div>
      </div>

      {/* Main content */}
      <main className="flex-1 overflow-auto lg:ml-64 pt-[72px] lg:pt-0">
        <div className="max-w-[1200px] mx-auto px-4 py-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Outlet />
          </motion.div>
        </div>
      </main>
    </div>
  );
}
