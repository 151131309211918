import { Card } from './ui/card';
import { Button } from './ui/button';
import { ExternalLink } from 'lucide-react';

export function DonateCard() {
  return (
    <Card className="p-6 bg-gradient-dark animate-gradient border border-white/5 backdrop-blur-sm rounded-2xl">
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold text-gradient-gold-white">Support GuapCoin</h2>
          <img 
            src="https://guapexplorer.com/ico/android-icon-192x192.png" 
            alt="GUAP" 
            className="w-8 h-8"
          />
        </div>
        
        <p className="text-white/80">
          Help us empower GuapCoin by donating to our iFundWomen campaign.
        </p>

        <Button
          onClick={() => window.open('https://www.ifundwomen.com/1-million-wallets-0', '_blank')}
          className="w-full bg-gradient-gold text-black hover:opacity-90 rounded-xl font-medium flex items-center justify-center space-x-2"
        >
          <span>Donate Now</span>
          <ExternalLink className="w-4 h-4" />
        </Button>
      </div>
    </Card>
  );
}
