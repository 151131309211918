import axios from 'axios';

export interface TransactionInput {
  addresses: string[];
  value: number;
}

export interface TransactionOutput {
  addresses: string[];
  value: number;
}

export interface Transaction {
  txid: string;
  type: 'sent' | 'received';
  amount: number;
  usdAmount?: number;
  timestamp: number;
  confirmations: number;
  fee?: number;
  inputs: TransactionInput[];
  outputs: TransactionOutput[];
  height: number;
  hex?: string;
}

export interface BalanceInfo {
  confirmed: number;
  unconfirmed: number;
  total: number;
  usdValue?: number;
  price?: number;
  priceChange24h?: number;
}

export interface PriceInfo {
  price: number;
  change24h: number;
  lastUpdated: number;
}

interface ApiRequestOptions {
  method?: string;
  data?: any;
  headers?: Record<string, string>;
}

// Get API URL from environment variable, fallback to localhost for development
const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3001';

// Generic API request function with error handling
const apiRequest = async (endpoint: string, options: ApiRequestOptions = {}) => {
  try {
    const config = {
      baseURL: API_URL,
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {})
      },
      ...options,
      url: endpoint,
    };

    // Log the full request configuration
    console.log('API Request Config:', {
      url: `${config.baseURL}${endpoint}`,
      method: config.method || 'GET',
      headers: config.headers,
      data: config.data
    });

    const response = await axios(config);
    console.log('API Response:', {
      endpoint,
      data: response.data
    });
    return response.data;
  } catch (error: any) {
    console.error('API Request Error:', {
      url: `${error.config?.baseURL}${error.config?.url}`,
      method: error.config?.method,
      headers: error.config?.headers,
      data: error.config?.data,
      error: error.response?.data || error
    });
    throw error;
  }
};

export const sendTransaction = async (
  userId: string,
  fromAddress: string,
  toAddress: string,
  amount: number
) => {
  return apiRequest('/api/guap/transaction/send', {
    method: 'POST',
    data: {
      userId,
      fromAddress,
      toAddress,
      amount
    }
  });
};

export const getWalletData = async (userId: string) => {
  return apiRequest(`/api/guap/wallet/data/${userId}`);
};

export const createWallet = async (userId: string, mnemonic?: string) => {
  return apiRequest('/api/guap/wallet/create', {
    method: 'POST',
    data: { userId, mnemonic }
  });
};

export const getBalance = async (address: string, coin: 'GUAP' | 'BTC' = 'GUAP'): Promise<BalanceInfo> => {
  console.log('Fetching balance and price data for:', { address, coin });
  
  const [balanceData, priceData] = await Promise.all([
    apiRequest(`/api/guap/wallet/balance/${address}`),
    apiRequest(`/api/price/${coin}`)
  ]);

  console.log('Balance and price data received:', {
    balanceData,
    priceData
  });

  const result = {
    ...balanceData,
    usdValue: balanceData.total * priceData.price,
    price: priceData.price,
    priceChange24h: priceData.change24h
  };

  console.log('Final balance info:', result);
  return result;
};

export const getTransactions = async (
  address: string,
  coin: 'GUAP' | 'BTC' = 'GUAP',
  page: number = 1,
  pageSize: number = 10
) => {
  const [txData, priceData] = await Promise.all([
    apiRequest(`/api/guap/wallet/transactions/${address}?coin=${coin}&page=${page}&pageSize=${pageSize}`),
    apiRequest(`/api/price/${coin}`)
  ]);

  return {
    ...txData,
    transactions: txData.transactions.map((tx: Transaction) => ({
      ...tx,
      usdAmount: tx.amount * priceData.price
    }))
  };
};

export const getPrice = async (coin: 'GUAP' | 'BTC'): Promise<PriceInfo> => {
  return apiRequest(`/api/price/${coin}`);
};
