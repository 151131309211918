import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '../hooks/use-toast';
import { generateMnemonic, createWalletFromMnemonic } from '../lib/wallet';
import { createWallet } from '../lib/api';
import WalletLayout from '../components/wallet/WalletLayout';
import MnemonicDisplay from '../components/wallet/MnemonicDisplay';
import MnemonicVerification from '../components/wallet/MnemonicVerification';

export default function CreateWallet() {
  const navigate = useNavigate();
  const { currentUser, setWalletInfo } = useAuth();
  const { toast } = useToast();
  const [step, setStep] = useState<'generate' | 'verify'>('generate');
  const [mnemonic, setMnemonic] = useState('');
  const [verificationWords, setVerificationWords] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string[]>(Array(3).fill(''));
  const [selectedIndices, setSelectedIndices] = useState<number[]>([]);

  const handleGenerateWallet = async () => {
    try {
      const newMnemonic = await generateMnemonic();
      setMnemonic(newMnemonic);
      
      // Select 3 random words for verification
      const words = newMnemonic.split(' ');
      const indices: number[] = [];
      while (indices.length < 3) {
        const index = Math.floor(Math.random() * words.length);
        if (!indices.includes(index)) {
          indices.push(index);
        }
      }
      indices.sort((a, b) => a - b);
      setSelectedIndices(indices);
      setVerificationWords(indices.map(i => words[i]));
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to generate wallet. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleContinue = () => {
    setStep('verify');
  };

  const handleInputChange = (index: number, value: string) => {
    const newInput = [...userInput];
    newInput[index] = value.toLowerCase().trim();
    setUserInput(newInput);
  };

  const handleVerify = async () => {
    const isCorrect = verificationWords.every((word, i) => word === userInput[i]);
    if (isCorrect) {
      try {
        if (!currentUser) {
          throw new Error('No user logged in');
        }

        // Create wallet from mnemonic
        const wallet = await createWalletFromMnemonic(mnemonic, 'GUAP');
        
        // Save wallet data to backend
        await createWallet(currentUser.uid, mnemonic);
        
        // Update local state
        setWalletInfo({
          address: wallet.address,
          mnemonic: wallet.mnemonic,
          coin: 'GUAP'
        });

        toast({
          title: "Wallet Created",
          description: "Your wallet has been created successfully!",
        });

        navigate('/dashboard');
      } catch (error: any) {
        console.error('Wallet creation error:', error);
        toast({
          title: "Error",
          description: error.message || "Failed to create wallet. Please try again.",
          variant: "destructive",
        });
        setUserInput(Array(3).fill(''));
      }
    } else {
      toast({
        title: "Verification Failed",
        description: "The words you entered don't match. Please try again.",
        variant: "destructive",
      });
      setUserInput(Array(3).fill(''));
    }
  };

  return (
    <WalletLayout>
      {step === 'verify' ? (
        <MnemonicVerification
          selectedIndices={selectedIndices}
          userInput={userInput}
          onInputChange={handleInputChange}
          onVerify={handleVerify}
        />
      ) : (
        <MnemonicDisplay
          mnemonic={mnemonic}
          onGenerate={handleGenerateWallet}
          onContinue={handleContinue}
        />
      )}
    </WalletLayout>
  );
}
