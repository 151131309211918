import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Check, Shield } from 'lucide-react';

interface MnemonicDisplayProps {
  mnemonic: string;
  onGenerate: () => void;
  onContinue: () => void;
}

export default function MnemonicDisplay({ mnemonic, onGenerate, onContinue }: MnemonicDisplayProps) {
  return (
    <div className="flex flex-col items-center space-y-6">
      <Shield className="w-12 h-12 text-guap-yellow" />
      <h1 className="text-2xl font-bold text-white text-center">
        Create Your Wallet
      </h1>
      <p className="text-gray-400 text-center">
        We'll generate a secure recovery phrase for your wallet. Make sure to write it down and keep it safe.
      </p>

      {mnemonic ? (
        <>
          <div className="w-full p-4 bg-black/20 rounded-lg">
            <div className="grid grid-cols-3 gap-2">
              {mnemonic.split(' ').map((word, i) => (
                <div key={i} className="text-sm">
                  <span className="text-gray-400">{i + 1}.</span>{' '}
                  <span className="text-white font-mono">{word}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full p-4 bg-black/20 rounded-lg text-center">
            <p className="text-sm text-red-400">
              Warning: Never share your recovery phrase with anyone. Anyone with these words can access your wallet.
            </p>
          </div>

          <Button
            onClick={onContinue}
            className="w-full bg-gradient-gold text-black hover:opacity-90"
          >
            <Check className="w-4 h-4 mr-2" />
            I've Saved My Recovery Phrase
          </Button>
        </>
      ) : (
        <Button
          onClick={onGenerate}
          className="w-full bg-gradient-gold text-black hover:opacity-90"
        >
          Generate Recovery Phrase
        </Button>
      )}
    </div>
  );
}
