import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ExternalLink } from 'lucide-react';

const faqs = [
  {
    question: "What is GuapCoin?",
    answer: "GuapCoin (GUAP) is a cryptocurrency designed to empower communities through financial technology. It operates on its own blockchain and aims to provide fast, secure transactions while promoting financial literacy and inclusion."
  },
  {
    question: "How can I buy GuapCoin?",
    answer: "You can buy GuapCoin (GUAP) on cryptocurrency exchanges like ProBit (GUAP/BTC pair) or through GuapSwap. Make sure to only use official exchanges and platforms to ensure safe transactions."
  },
  {
    question: "What makes GuapCoin unique?",
    answer: "GuapCoin stands out through its community-focused approach, educational initiatives, and commitment to financial empowerment. The project combines blockchain technology with practical financial solutions for everyday users."
  },
  {
    question: "How do I secure my GuapCoin wallet?",
    answer: "To secure your GuapCoin wallet: 1) Never share your private keys or recovery phrase, 2) Enable all available security features, 3) Keep your recovery phrase in a safe place, 4) Regularly backup your wallet, and 5) Be cautious of phishing attempts."
  },
  {
    question: "Where can I learn more about GuapCoin?",
    answer: "Visit the official GuapCoin website (guapcoin.org) for detailed information about the project, its goals, and latest developments. You can also join the community through official social media channels."
  }
];

export default function FAQ() {
  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-white">Frequently Asked Questions</h1>

      <div className="grid gap-6">
        <Card className="bg-guap-dark text-white p-6">
          <Accordion type="single" collapsible className="space-y-4">
            {faqs.map((faq, index) => (
              <AccordionItem key={index} value={`item-${index}`} className="border-b-guap-yellow">
                <AccordionTrigger className="text-left hover:text-guap-yellow">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="text-gray-400">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </Card>

        <Card className="bg-guap-dark text-white p-6">
          <h2 className="text-xl font-bold mb-4">Useful Links</h2>
          <div className="grid gap-4">
            <Button asChild variant="outline" className="w-full border-guap-yellow justify-start">
              <a 
                href="https://www.guapcoin.org" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center gap-2"
              >
                <ExternalLink className="w-4 h-4" />
                Official Website
              </a>
            </Button>
            <Button asChild variant="outline" className="w-full border-guap-yellow justify-start">
              <a 
                href="https://www.probit.com/en-us/app/exchange/GUAP-BTC" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center gap-2"
              >
                <ExternalLink className="w-4 h-4" />
                Trade on ProBit
              </a>
            </Button>
            <Button asChild variant="outline" className="w-full border-guap-yellow justify-start">
              <a 
                href="https://www.guapswap.com" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center gap-2"
              >
                <ExternalLink className="w-4 h-4" />
                GuapSwap Exchange
              </a>
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}