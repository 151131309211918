import { Copy } from 'lucide-react';
import { useToast } from '../hooks/use-toast';

interface AddressDisplayProps {
  address: string;
}

export function AddressDisplay({ address }: AddressDisplayProps) {
  const { toast } = useToast();

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      toast({
        title: "Address copied!",
        duration: 2000,
      });
    } catch (err) {
      toast({
        title: "Failed to copy address",
        variant: "destructive",
      });
    }
  };

  return (
    <div 
      className="flex flex-col sm:flex-row sm:items-center gap-2 cursor-pointer group mb-4"
      onClick={copyToClipboard}
    >
      <span className="text-white/60 text-sm whitespace-nowrap">Your Address:</span>
      <div className="flex items-center gap-2 flex-1 min-w-0">
        <span className="text-white font-mono hover:text-yellow-500 transition-colors truncate">
          {address}
        </span>
        <Copy className="w-4 h-4 text-white/60 hover:text-yellow-500 transition-colors flex-shrink-0" />
      </div>
    </div>
  );
}
