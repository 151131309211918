import { useState } from 'react';
import { Mail, Lock } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { useToast } from '../../hooks/use-toast';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { createWallet } from '../../lib/api';
import { generateMnemonic } from '../../lib/wallet';
import { getFirebaseAuth } from '../../lib/firebase-lazy';

export function RegisterForm() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Get Firebase Auth instance
      const auth = await getFirebaseAuth();
      
      // Create user account
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Generate mnemonic and create wallet immediately
      const mnemonic = await generateMnemonic();
      
      // Create wallet document in Firestore
      await createWallet(userId, mnemonic);

      // Show success message
      toast({
        title: "Account Created",
        description: "Please verify your mnemonic phrase.",
      });

      // Navigate to create wallet page for mnemonic verification
      navigate('/create-wallet', { state: { mnemonic } });
    } catch (error: any) {
      console.error('Registration error:', error);
      toast({
        title: "Registration Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleRegister} className="space-y-4">
      <div className="space-y-2">
        <div className="relative">
          <Mail className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            className="pl-10 bg-[#232323] border-guap-yellow text-white rounded-xl"
            required
          />
        </div>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            className="pl-10 bg-[#232323] border-guap-yellow text-white rounded-xl"
            required
          />
        </div>
      </div>

      <Button
        type="submit"
        className="w-full bg-gradient-gold text-black hover:opacity-90 rounded-xl font-medium"
        disabled={isLoading}
      >
        {isLoading ? 'Creating Account...' : 'Create Account'}
      </Button>
    </form>
  );
}
