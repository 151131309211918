import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { useToast } from '../hooks/use-toast';
import { useAuth } from '../contexts/AuthContext';
import { createWalletFromMnemonic } from '../lib/wallet';

export default function ImportWallet() {
  const [mnemonic, setMnemonic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setWalletInfo } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleImport = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Clean up mnemonic input
      const cleanMnemonic = mnemonic.trim().toLowerCase();
      
      // Validate mnemonic (12 or 24 words)
      const words = cleanMnemonic.split(' ');
      if (words.length !== 12 && words.length !== 24) {
        throw new Error('Invalid mnemonic phrase. Must be 12 or 24 words.');
      }

      // Create wallet from mnemonic
      const walletInfo = await createWalletFromMnemonic(cleanMnemonic, 'GUAP');

      // Set wallet info
      await setWalletInfo({
        ...walletInfo,
        additionalAddresses: []
      });

      toast({
        title: "Wallet Imported",
        description: "Your wallet has been successfully imported.",
      });
      navigate('/');
    } catch (error: any) {
      toast({
        title: "Import Failed",
        description: error.message || "Failed to import wallet",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-white">Import Wallet</h1>
      <Card className="bg-guap-dark text-white p-6">
        <form onSubmit={handleImport} className="space-y-6">
          <div className="space-y-2">
            <label className="text-sm text-gray-400">
              Enter Recovery Phrase
            </label>
            <Input
              value={mnemonic}
              onChange={(e) => setMnemonic(e.target.value)}
              className="bg-black/20 border-guap-border text-white"
              placeholder="Enter your 12 or 24 word recovery phrase"
              required
            />
            <p className="text-xs text-gray-400">
              Enter your recovery phrase words separated by spaces
            </p>
          </div>

          <div className="space-y-4">
            <Button
              type="submit"
              className="w-full bg-gradient-gold text-black hover:opacity-90"
              disabled={isLoading}
            >
              {isLoading ? "Importing..." : "Import Wallet"}
            </Button>

            <div className="p-4 bg-black/20 rounded-lg">
              <h3 className="font-medium mb-2">Important:</h3>
              <ul className="text-sm text-gray-400 space-y-2">
                <li>• Never share your recovery phrase with anyone</li>
                <li>• GUAP will never ask for your recovery phrase</li>
                <li>• Store your recovery phrase in a secure location</li>
              </ul>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
}
