import { useQuery } from 'react-query';
import { Card } from './ui/card';
import { getPrice } from '../lib/api';
import { useMemo } from 'react';

interface PriceChartProps {
  coin: 'GUAP' | 'BTC';
}

export default function PriceChart({ coin }: PriceChartProps) {
  const { data: priceData } = useQuery(
    ['price', coin],
    () => getPrice(coin),
    { 
      refetchInterval: 120000, // Increased to 2 minutes
      retry: 2,
      retryDelay: 5000, // Wait 5 seconds between retries
      staleTime: 60000, // Consider data fresh for 1 minute
    }
  );

  const priceChange = priceData?.change24h ?? 0;
  const isPositive = priceChange >= 0;

  // Generate sample points for the sparkline
  const points = useMemo(() => {
    const numPoints = 50;
    const variance = priceData?.price ? priceData.price * 0.02 : 1; // 2% variance
    const basePrice = priceData?.price ?? 0;
    
    const values = Array.from({ length: numPoints }, (_, i) => {
      const t = i / (numPoints - 1);
      const randomVariance = (Math.random() - 0.5) * variance;
      return basePrice + randomVariance;
    });

    const min = Math.min(...values);
    const max = Math.max(...values);
    const range = max - min;

    return values.map((val, i) => {
      const x = (i / (numPoints - 1)) * 100;
      const y = ((val - min) / range) * 100;
      return `${x},${100 - y}`;
    }).join(' ');
  }, [priceData?.price]);

  return (
    <Card className="p-6 bg-guap-dark text-white">
      <div className="flex justify-between items-start mb-4">
        <div>
          <p className="text-sm text-gray-400">{coin} Price</p>
          <div className="flex items-baseline gap-2">
            <h2 className="text-lg font-bold">
              ${(priceData?.price ?? 0).toLocaleString(undefined, { 
                minimumFractionDigits: 8, 
                maximumFractionDigits: 8 
              })}
            </h2>
            <span className={`text-xs ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
              {isPositive ? '+' : ''}{priceChange.toFixed(2)}%
            </span>
          </div>
        </div>
      </div>

      <div className="relative w-full h-[200px]">
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          className="w-full h-full"
        >
          <defs>
            <linearGradient id={`gradient-${coin}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FFDD67" stopOpacity="0.2" />
              <stop offset="100%" stopColor="#FFDD67" stopOpacity="0" />
            </linearGradient>
          </defs>
          <polyline
            points={points}
            fill="none"
            stroke="#FFDD67"
            strokeWidth="0.5"
            vectorEffect="non-scaling-stroke"
          />
          <polygon
            points={`0,100 ${points} 100,100`}
            fill={`url(#gradient-${coin})`}
          />
        </svg>
      </div>
    </Card>
  );
}
