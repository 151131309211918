import { useQuery } from 'react-query';
import { useState } from 'react';
import { format } from 'date-fns';
import { ArrowUpRight, ArrowDownRight, ExternalLink } from 'lucide-react';
import { Button } from '../components/ui/button';
import { Card } from '../components/ui/card';
import {
  getTransactions,
  Transaction,
  TransactionInput,
  TransactionOutput,
} from '../lib/api';
import { useAuth } from '../contexts/AuthContext';

const EXPLORER_URLS: Record<string, string> = {
  GUAP: 'https://ex1.guapcoin.org/tx/',
  BTC: 'https://btc1.trezor.io/tx/',
};

export default function TransactionList() {
  const [page, setPage] = useState<number>(1);
  const { walletInfo } = useAuth();
  const pageSize = 10;

  const coin = walletInfo?.coin || 'GUAP';
  const address = walletInfo?.address || '';

  const { data, isLoading, isFetching } = useQuery(
    ['transactions', address, coin, page],
    () => getTransactions(address, coin, page, pageSize),
    {
      keepPreviousData: true,
      enabled: !!address,
      retry: 3,
      refetchInterval: 30000, // Refresh every 30 seconds
      staleTime: 10000, // Consider data stale after 10 seconds
    }
  );

  if (!address) {
    return (
      <Card className="bg-guap-dark text-white p-6">
        <div className="text-center text-gray-400">
          No wallet address available. Please create or recover a wallet.
        </div>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card className="bg-guap-dark text-white p-6">
        <div className="text-center text-gray-400">Loading transactions...</div>
      </Card>
    );
  }

  const calculateNetAmount = (tx: Transaction) => {
    const myAddress = address;
    let netAmount = 0;

    if (tx.outputs && tx.inputs) {
      // Calculate total amount received to my address
      const totalReceived = tx.outputs
        .filter((output: TransactionOutput) =>
          output.addresses?.includes(myAddress)
        )
        .reduce((sum: number, output: TransactionOutput) => sum + output.value, 0);

      // Calculate total amount sent from my address
      const totalSent = tx.inputs
        .filter((input: TransactionInput) =>
          input.addresses?.includes(myAddress)
        )
        .reduce((sum: number, input: TransactionInput) => sum + input.value, 0);

      netAmount = totalReceived - totalSent;
    } else {
      // Fallback to tx.amount if detailed info is not available
      netAmount = tx.amount || 0;
    }

    return netAmount;
  };

  return (
    <Card className="bg-guap-dark text-white p-6">
      <h2 className="text-xl font-bold mb-4">Recent Transactions</h2>
      <div className="space-y-4">
        {data?.transactions.length === 0 ? (
          <div className="text-center text-gray-400 py-8">
            No transactions found
          </div>
        ) : (
          data?.transactions.map((tx: Transaction) => {
            const netAmount = calculateNetAmount(tx);
            const isReceived = netAmount > 0;

            return (
              <div
                key={tx.txid}
                className="flex items-center justify-between p-4 rounded-lg bg-black/20 border border-guap-border"
              >
                <div className="flex items-center gap-4">
                  {isReceived ? (
                    <ArrowDownRight className="w-6 h-6 text-green-500" />
                  ) : (
                    <ArrowUpRight className="w-6 h-6 text-red-500" />
                  )}
                  <div>
                    <p className="font-medium">
                      {isReceived ? 'Received' : 'Sent'}
                    </p>
                    <p className="text-sm text-gray-400">
                      {format(tx.timestamp * 1000, 'MMM d, yyyy HH:mm')}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-right">
                    <p className="font-bold">
                      {Math.abs(netAmount).toFixed(8)} {coin}
                    </p>
                    {/* Only show fee for BTC transactions */}
                    {coin === 'BTC' && typeof tx.fee === 'number' && (
                      <p className="text-sm text-gray-400">
                        Fee: {tx.fee.toFixed(8)} {coin}
                      </p>
                    )}
                  </div>
                  <a
                    href={`${EXPLORER_URLS[coin] ?? EXPLORER_URLS['GUAP']}${tx.txid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-guap-yellow hover:opacity-80"
                  >
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </div>
            );
          })
        )}

        <div className="flex justify-between items-center mt-4 pt-4 border-t border-guap-border">
          <Button
            variant="outline"
            onClick={() => setPage((p) => Math.max(p - 1, 1))}
            disabled={page === 1 || isFetching}
            className="border-guap-yellow"
          >
            Previous
          </Button>
          <span className="text-gray-400">Page {page}</span>
          <Button
            variant="outline"
            onClick={() => setPage((p) => p + 1)}
            disabled={!data?.hasMore || isFetching}
            className="border-guap-yellow"
          >
            Next
          </Button>
        </div>
      </div>
    </Card>
  );
}
