import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ChevronDown } from 'lucide-react';

interface Address {
  address: string;
  label: string;
}

interface AddressSelectProps {
  addresses: Address[];
  selectedAddress: string;
  onAddressChange: (address: string) => void;
}

export default function AddressSelect({
  addresses,
  selectedAddress,
  onAddressChange,
}: AddressSelectProps) {
  const selectedLabel = addresses.find(a => a.address === selectedAddress)?.label || 'Select Address';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="w-full justify-between border-guap-yellow bg-black/20"
        >
          <span className="truncate">{selectedLabel}</span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-full min-w-[240px] bg-guap-dark border-guap-yellow">
        {addresses.map((addr) => (
          <DropdownMenuItem
            key={addr.address}
            onClick={() => onAddressChange(addr.address)}
            className="text-white hover:bg-white/10"
          >
            <div className="flex flex-col">
              <span>{addr.label}</span>
              <span className="text-xs text-gray-400 truncate">{addr.address}</span>
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}