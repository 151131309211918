export const API_BASE_URL = 'http://localhost:3001/api';

export const NETWORKS = {
  GUAP: {
    messagePrefix: '\x18Guapcoin Signed Message:\n',
    bech32: 'ps',
    bip32: {
      public: 0x0488b21e,
      private: 0x0488ade4,
    },
    pubKeyHash: 0x26,
    scriptHash: 0x06,
    wif: 0x2E,
  },
  BTC: {
    messagePrefix: '\x18Bitcoin Signed Message:\n',
    bech32: 'bc',
    bip32: {
      public: 0x04b24746,
      private: 0x04b2430c,
    },
    pubKeyHash: 0x00,
    scriptHash: 0x05,
    wif: 0x80,
  }
} as const;

export const TX_CONFIG = {
  DUST_THRESHOLD: 546,
  FEE_RATE: 10,
  MIN_FEE: 1000,
  MAX_FEE: 100000,
  ESTIMATED_INPUT_SIZE: 180,
  ESTIMATED_OUTPUT_SIZE: 34,
  ESTIMATED_OVERHEAD: 10,
} as const;
