import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { AlertTriangle } from 'lucide-react';

interface MnemonicVerificationProps {
  selectedIndices: number[];
  userInput: string[];
  onInputChange: (index: number, value: string) => void;
  onVerify: () => void;
}

export default function MnemonicVerification({
  selectedIndices,
  userInput,
  onInputChange,
  onVerify,
}: MnemonicVerificationProps) {
  return (
    <div className="flex flex-col items-center space-y-6">
      <AlertTriangle className="w-12 h-12 text-guap-yellow" />
      <h1 className="text-2xl font-bold text-white text-center">
        Verify Recovery Phrase
      </h1>
      <p className="text-gray-400 text-center">
        Please enter the following words from your recovery phrase to verify you've saved it correctly.
      </p>

      <div className="w-full space-y-4">
        {selectedIndices.map((index, i) => (
          <div key={index} className="space-y-2">
            <label className="text-sm text-gray-400">
              Word #{index + 1}
            </label>
            <Input
              value={userInput[i]}
              onChange={(e) => onInputChange(i, e.target.value)}
              className="bg-black/20 border-guap-yellow text-white"
              placeholder={`Enter word #${index + 1}`}
              required
            />
          </div>
        ))}
      </div>

      <Button
        onClick={onVerify}
        className="w-full bg-gradient-gold text-black hover:opacity-90"
      >
        Verify & Continue
      </Button>
    </div>
  );
}