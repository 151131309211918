import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { generateEncryptionKey, encryptMnemonic, decryptMnemonic } from './crypto';

const firebaseConfig = {
  apiKey: "AIzaSyCLp5A0mvzpbAdxdsgsJvhIZDtOwSb-DTs",
  authDomain: "guap-wallet-1d3f7.firebaseapp.com",
  projectId: "guap-wallet-1d3f7",
  storageBucket: "guap-wallet-1d3f7.firebasestorage.app",
  messagingSenderId: "794216787861",
  appId: "1:794216787861:web:368eceff342b735d3b509c",
  measurementId: "G-CQVZ4VFTQW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

interface AdditionalAddress {
  address: string;
  path: string;
  index: number;
}

interface WalletData {
  encryptedMnemonic: string;
  encryptionKey: JsonWebKey;
  address: string;
  coin: 'GUAP' | 'BTC';
  additionalAddresses?: AdditionalAddress[];
}

export async function storeWalletData(
  userId: string,
  mnemonic: string,
  address: string,
  coin: 'GUAP' | 'BTC',
  additionalAddresses?: AdditionalAddress[]
): Promise<void> {
  try {
    // Generate encryption key
    const key = await generateEncryptionKey();
    
    // Export key for storage
    const exportedKey = await window.crypto.subtle.exportKey('jwk', key);
    
    // Ensure required JWK properties are set
    const completeKey: JsonWebKey = {
      ...exportedKey,
      kty: 'oct',  // Required for symmetric keys
      alg: 'A256GCM',  // Specify the algorithm
      use: 'enc'  // Specify the key usage
    };
    
    // Encrypt mnemonic
    const encryptedMnemonic = await encryptMnemonic(mnemonic, key);
    
    // Store in Firestore
    const walletRef = doc(collection(db, 'wallets'), userId);
    await setDoc(walletRef, {
      encryptedMnemonic,
      encryptionKey: completeKey,
      address,
      coin,
      additionalAddresses: additionalAddresses || []
    });
  } catch (error) {
    console.error('Error storing wallet data:', error);
    throw new Error('Failed to store wallet data securely');
  }
}

export async function retrieveWalletData(userId: string): Promise<{
  mnemonic: string;
  address: string;
  coin: 'GUAP' | 'BTC';
  additionalAddresses?: AdditionalAddress[];
} | null> {
  try {
    const walletRef = doc(collection(db, 'wallets'), userId);
    const walletDoc = await getDoc(walletRef);
    
    if (!walletDoc.exists()) {
      return null;
    }
    
    const data = walletDoc.data() as WalletData;
    
    // Import the encryption key
    const key = await window.crypto.subtle.importKey(
      'jwk',
      data.encryptionKey,
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );
    
    // Decrypt the mnemonic
    const mnemonic = await decryptMnemonic(data.encryptedMnemonic, key);
    
    return {
      mnemonic,
      address: data.address,
      coin: data.coin,
      additionalAddresses: data.additionalAddresses
    };
  } catch (error) {
    console.error('Error retrieving wallet data:', error);
    throw new Error('Failed to retrieve wallet data');
  }
}
