import * as React from 'react';
import { cn } from '../../lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors',
          'file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground',
          'placeholder:text-muted-foreground',
          'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[rgb(30,30,30)]',
          'disabled:cursor-not-allowed disabled:opacity-50',
          '[&:-webkit-autofill]:bg-[#1a1a1a] [&:-webkit-autofill]:!text-white [&:-webkit-autofill]:shadow-[0_0_0_30px_#1a1a1a_inset]',
          '[&:-webkit-autofill:hover]:bg-[#1a1a1a] [&:-webkit-autofill:hover]:!text-white [&:-webkit-autofill:hover]:shadow-[0_0_0_30px_#1a1a1a_inset]',
          '[&:-webkit-autofill:focus]:bg-[#1a1a1a] [&:-webkit-autofill:focus]:!text-white [&:-webkit-autofill:focus]:shadow-[0_0_0_30px_#1a1a1a_inset]',
          '[&:-webkit-autofill:active]:bg-[#1a1a1a] [&:-webkit-autofill:active]:!text-white [&:-webkit-autofill:active]:shadow-[0_0_0_30px_#1a1a1a_inset]',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
