import { useState } from 'react';
import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction, AlertDialogTrigger } from '@/components/ui/alert-dialog';
import { Key, Plus, Eye, EyeOff, Loader2 } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import * as bitcoin from 'bitcoinjs-lib';
import * as bip39 from 'bip39';
import { BIP32Factory } from 'bip32';
import * as ecc from 'tiny-secp256k1';
import { NETWORKS } from '@/lib/constants';

// Initialize bip32 with secp256k1
const bip32 = BIP32Factory(ecc);

interface AdditionalAddress {
  address: string;
  path: string;
  index: number;
}

export default function Settings() {
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { toast } = useToast();
  const { walletInfo, setWalletInfo } = useAuth();

  const copyToClipboard = async (text: string, type: string) => {
    await navigator.clipboard.writeText(text);
    toast({
      title: "Copied!",
      description: `${type} has been copied to your clipboard.`,
    });
  };

  const generateNewAddress = async () => {
    if (!walletInfo?.mnemonic) {
      toast({
        title: "Error",
        description: "Wallet information not available.",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsGenerating(true);

      // Get the next index
      const currentAddresses = walletInfo.additionalAddresses || [];
      const nextIndex = currentAddresses.length + 1;

      // Convert mnemonic to seed
      const seed = await bip39.mnemonicToSeed(walletInfo.mnemonic);
      const root = bip32.fromSeed(seed);
      
      // Derive path based on coin
      const basePath = walletInfo.coin === 'GUAP' 
        ? "m/44'/119'/0'/0"  // GuapCoin path
        : "m/84'/0'/0'/0";   // Bitcoin path

      const path = `${basePath}/${nextIndex}`; // Start from index 1 since 0 is the primary address
      const keyPair = root.derivePath(path);
      
      // Generate address based on coin
      const network = NETWORKS[walletInfo.coin || 'GUAP'];
      const { address } = bitcoin.payments.p2pkh({
        pubkey: keyPair.publicKey,
        network,
      });

      if (!address) {
        throw new Error('Failed to generate address');
      }

      const newAddress: AdditionalAddress = {
        address,
        path,
        index: nextIndex,
      };

      const updatedAddresses = [...currentAddresses, newAddress];

      // Update wallet info with new address
      setWalletInfo({
        ...walletInfo,
        additionalAddresses: updatedAddresses,
      });

      toast({
        title: "Address Generated",
        description: "New address has been generated successfully.",
      });

    } catch (error) {
      console.error('Error generating address:', error);
      toast({
        title: "Error",
        description: "Failed to generate new address. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  if (!walletInfo?.mnemonic) {
    return (
      <div className="text-center text-gray-400">
        No wallet information available. Please create or recover a wallet.
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-white">Settings</h1>

      <div className="grid gap-6">
        <Card className="bg-guap-dark text-white p-6">
          <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
            <Key className="w-5 h-5 text-guap-yellow" />
            Wallet Security
          </h2>
          
          <div className="space-y-6">
            <div>
              <Label className="text-sm text-gray-400">Primary Wallet Address</Label>
              <div className="mt-2 flex items-center gap-2">
                <Input
                  type="text"
                  value={walletInfo.address}
                  readOnly
                  className="bg-black/20 border-guap-yellow font-mono"
                />
                <Button
                  variant="outline"
                  onClick={() => copyToClipboard(walletInfo.address, "Wallet address")}
                  className="border-guap-yellow"
                >
                  Copy
                </Button>
              </div>
            </div>

            <div>
              <Label className="text-sm text-gray-400">Recovery Phrase</Label>
              <div className="mt-2 p-4 bg-black/20 rounded-lg">
                <p className="font-mono text-sm break-all">
                  {showPrivateKey ? walletInfo.mnemonic : '••••••••••••••••••••••••••'}
                </p>
              </div>
              <div className="flex gap-2 mt-2">
                <Button
                  variant="outline"
                  onClick={() => setShowPrivateKey(!showPrivateKey)}
                  className="border-guap-yellow"
                >
                  {showPrivateKey ? (
                    <><EyeOff className="w-4 h-4 mr-2" /> Hide</>
                  ) : (
                    <><Eye className="w-4 h-4 mr-2" /> Show</>
                  )}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => copyToClipboard(walletInfo.mnemonic!, "Recovery phrase")}
                  className="border-guap-yellow"
                >
                  Copy
                </Button>
              </div>
              <p className="mt-4 text-sm text-red-400">
                Warning: Never share your recovery phrase with anyone. Anyone with these words can access your wallet.
              </p>
            </div>
          </div>
        </Card>

        <Card className="bg-guap-dark text-white p-6">
          <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
            <Plus className="w-5 h-5 text-guap-yellow" />
            Additional Addresses
          </h2>
          
          <div className="space-y-4">
            {(walletInfo.additionalAddresses || []).map((addr) => (
              <div key={addr.path} className="p-4 bg-black/20 rounded-lg space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm text-gray-400">Address #{addr.index}</span>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => copyToClipboard(addr.address, `Address #${addr.index}`)}
                    className="border-guap-yellow"
                  >
                    Copy
                  </Button>
                </div>
                <p className="font-mono text-sm break-all">{addr.address}</p>
                <p className="text-xs text-gray-400">Path: {addr.path}</p>
              </div>
            ))}

            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button className="w-full bg-gradient-gold text-black hover:opacity-90">
                  {isGenerating ? (
                    <><Loader2 className="w-4 h-4 mr-2 animate-spin" /> Generating...</>
                  ) : (
                    <>Generate New Address</>
                  )}
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent className="bg-guap-dark border-guap-yellow">
                <AlertDialogHeader>
                  <AlertDialogTitle className="text-white">Generate New Address</AlertDialogTitle>
                  <AlertDialogDescription className="text-gray-400">
                    This will create a new address under your current wallet. You can use it to separate your funds or for different purposes.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel className="border-guap-yellow text-white">Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={generateNewAddress}
                    className="bg-gradient-gold text-black"
                    disabled={isGenerating}
                  >
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </Card>
      </div>
    </div>
  );
}