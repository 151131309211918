import { useQuery } from 'react-query';
import { Card } from './ui/card';
import { getBalance } from '../lib/api';
import { Loader2 } from 'lucide-react';

interface BalanceCardProps {
  address: string;
  coin: 'GUAP' | 'BTC';
}

export default function BalanceCard({ address, coin }: BalanceCardProps) {
  // Get balance with price data
  const { data: balanceData, isLoading: isLoadingBalance } = useQuery(
    ['balance', address, coin],
    () => getBalance(address, coin),
    {
      enabled: !!address,
      refetchInterval: 60000, // Increased to 1 minute
      retry: 2,
      retryDelay: 5000, // Wait 5 seconds between retries
      staleTime: 30000, // Consider data fresh for 30 seconds
      onSuccess: (data) => {
        console.log('Balance data received:', {
          total: data.total,
          unconfirmed: data.unconfirmed,
          price: data.price,
          priceChange24h: data.priceChange24h
        });
      },
      onError: (error) => {
        console.error(`Error fetching ${coin} balance:`, error);
      }
    }
  );

  if (isLoadingBalance) {
    return (
      <Card className="p-6 bg-guap-dark text-white">
        <div className="flex items-center justify-center">
          <Loader2 className="w-6 h-6 animate-spin" />
        </div>
      </Card>
    );
  }

  const balance = balanceData?.total || 0;
  const unconfirmedBalance = balanceData?.unconfirmed || 0;
  const price = balanceData?.price ?? 0.00000100;
  const priceChange = balanceData?.priceChange24h ?? 0;
  const usdValue = balance * price;
  const isPositive = priceChange >= 0;

  console.log('Rendering BalanceCard with:', {
    balance,
    unconfirmedBalance,
    price,
    priceChange,
    usdValue
  });

  return (
    <Card className="p-6 bg-guap-dark text-white">
      <div className="space-y-4">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-medium text-gray-400">{coin} Balance</h3>
            <p className="text-3xl font-bold">
              {balance.toFixed(8)} {coin}
            </p>
            {unconfirmedBalance !== 0 && (
              <p className="text-sm text-gray-400">
                Pending: {unconfirmedBalance.toFixed(8)} {coin}
              </p>
            )}
          </div>
          <div className="text-right">
            <p className="text-lg font-medium text-gray-400">USD Value</p>
            <p className="text-2xl font-bold">
              ${usdValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center pt-4 border-t border-guap-border">
          <div>
            <p className="text-sm text-gray-400">Price</p>
            <p className="text-lg font-medium">
              ${price.toLocaleString(undefined, {
                minimumFractionDigits: 8,
                maximumFractionDigits: 8,
              })}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-400">24h Change</p>
            <p className={`text-lg font-medium ${
              isPositive ? 'text-green-500' : 'text-red-500'
            }`}>
              {isPositive ? '+' : ''}{priceChange.toFixed(2)}%
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}
