import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';

let app: FirebaseApp | null = null;
let auth: Auth | null = null;
let db: Firestore | null = null;

export async function initializeFirebase() {
  if (!app) {
    const { initializeApp } = await import('firebase/app');
    const { getAuth } = await import('firebase/auth');
    const { getFirestore } = await import('firebase/firestore');

    const firebaseConfig = {
      apiKey: "AIzaSyCLp5A0mvzpbAdxdsgsJvhIZDtOwSb-DTs",
      authDomain: "guap-wallet-1d3f7.firebaseapp.com",
      projectId: "guap-wallet-1d3f7",
      storageBucket: "guap-wallet-1d3f7.firebasestorage.app",
      messagingSenderId: "794216787861",
      appId: "1:794216787861:web:368eceff342b735d3b509c",
      measurementId: "G-CQVZ4VFTQW"
    };

    app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    db = getFirestore(app);
  }

  return { app, auth, db };
}

export async function getFirebaseAuth(): Promise<Auth> {
  if (!auth) {
    const { auth: newAuth } = await initializeFirebase();
    if (!newAuth) throw new Error('Failed to initialize Firebase Auth');
    return newAuth;
  }
  return auth;
}

export async function getFirebaseDb(): Promise<Firestore> {
  if (!db) {
    const { db: newDb } = await initializeFirebase();
    if (!newDb) throw new Error('Failed to initialize Firestore');
    return newDb;
  }
  return db;
}

interface AdditionalAddress {
  address: string;
  path: string;
  index: number;
}

interface WalletData {
  encryptedMnemonic: string;
  encryptionKey: JsonWebKey;
  address: string;
  coin: 'GUAP' | 'BTC';
  additionalAddresses?: AdditionalAddress[];
}

export async function storeWalletData(
  userId: string,
  mnemonic: string,
  address: string,
  coin: 'GUAP' | 'BTC',
  additionalAddresses?: AdditionalAddress[]
): Promise<void> {
  const { collection, doc, setDoc } = await import('firebase/firestore');
  const { generateEncryptionKey, encryptMnemonic } = await import('./crypto');
  const db = await getFirebaseDb();

  try {
    // Generate encryption key and encrypt mnemonic
    const { encryptedData, encryptionKey } = await encryptMnemonic(mnemonic);
    
    // Store in Firestore
    const walletRef = doc(collection(db, 'wallets'), userId);
    await setDoc(walletRef, {
      encryptedMnemonic: encryptedData,
      encryptionKey,
      address,
      coin,
      additionalAddresses: additionalAddresses || []
    });
  } catch (error) {
    console.error('Error storing wallet data:', error);
    throw new Error('Failed to store wallet data securely');
  }
}

export async function retrieveWalletData(userId: string): Promise<{
  mnemonic: string;
  address: string;
  coin: 'GUAP' | 'BTC';
  additionalAddresses?: AdditionalAddress[];
} | null> {
  const { collection, doc, getDoc } = await import('firebase/firestore');
  const { decryptMnemonic } = await import('./crypto');
  const db = await getFirebaseDb();

  try {
    const walletRef = doc(collection(db, 'wallets'), userId);
    const walletDoc = await getDoc(walletRef);
    
    if (!walletDoc.exists()) {
      return null;
    }
    
    const data = walletDoc.data() as WalletData;
    
    // Decrypt the mnemonic
    const mnemonic = await decryptMnemonic(data.encryptedMnemonic, data.encryptionKey);
    
    return {
      mnemonic,
      address: data.address,
      coin: data.coin,
      additionalAddresses: data.additionalAddresses
    };
  } catch (error) {
    console.error('Error retrieving wallet data:', error);
    throw new Error('Failed to retrieve wallet data');
  }
}
