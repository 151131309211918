import * as bitcoin from 'bitcoinjs-lib';
import * as bip39 from 'bip39';
import { BIP32Factory } from 'bip32';
import * as ecc from 'tiny-secp256k1';
import { NETWORKS } from './constants';

const bip32 = BIP32Factory(ecc);

const guapcoinNetwork = NETWORKS.GUAP as bitcoin.networks.Network;
const bitcoinNetwork = bitcoin.networks.bitcoin;

const DEMO_WALLETS = {
  GUAP: {
    address: 'GYNVuLJHhkCxMwQHDzRmwpQzKxoyoMUVyY',
    mnemonic: 'demo wallet - no private key access'
  },
  BTC: {
    address: '1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa',
    mnemonic: 'demo wallet - no private key access'
  }
};

export async function generateMnemonic(): Promise<string> {
  try {
    return bip39.generateMnemonic(256);
  } catch (error) {
    console.error('Error generating mnemonic:', error);
    throw new Error('Failed to generate mnemonic');
  }
}

export async function validateMnemonic(mnemonic: string): Promise<boolean> {
  try {
    if (mnemonic === DEMO_WALLETS.GUAP.mnemonic || mnemonic === DEMO_WALLETS.BTC.mnemonic) {
      return true;
    }
    return bip39.validateMnemonic(mnemonic);
  } catch (error) {
    return false;
  }
}

export async function validateAddress(address: string, coin: 'GUAP' | 'BTC'): Promise<boolean> {
  try {
    if (address === DEMO_WALLETS[coin].address) {
      return true;
    }
    
    const network = coin === 'GUAP' ? guapcoinNetwork : bitcoinNetwork;
    bitcoin.address.toOutputScript(address, network);
    return true;
  } catch (error) {
    return false;
  }
}

export async function createWalletFromMnemonic(mnemonic: string, coin: 'GUAP' | 'BTC') {
  try {
    if (mnemonic === DEMO_WALLETS.GUAP.mnemonic || mnemonic === DEMO_WALLETS.BTC.mnemonic) {
      return {
        address: DEMO_WALLETS[coin].address,
        mnemonic: DEMO_WALLETS[coin].mnemonic,
        coin
      };
    }

    if (!await validateMnemonic(mnemonic)) {
      throw new Error('Invalid mnemonic');
    }

    const seed = await bip39.mnemonicToSeed(mnemonic);
    const root = bip32.fromSeed(seed);
    const path = coin === 'GUAP' ? "m/44'/119'/0'/0/0" : "m/84'/0'/0'/0/0";
    const keyPair = root.derivePath(path);
    const network = coin === 'GUAP' ? guapcoinNetwork : bitcoinNetwork;
    
    const { address } = bitcoin.payments.p2pkh({
      pubkey: keyPair.publicKey,
      network,
    });

    if (!address) {
      throw new Error('Failed to generate address');
    }
    
    return { address, mnemonic, coin };
  } catch (error) {
    console.error('Error creating wallet:', error);
    throw new Error('Failed to create wallet');
  }
}

export async function createDemoWallet(coin: 'GUAP' | 'BTC' = 'GUAP') {
  return {
    address: DEMO_WALLETS[coin].address,
    mnemonic: DEMO_WALLETS[coin].mnemonic,
    coin
  };
}
