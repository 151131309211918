import { useQuery } from 'react-query';
import { motion } from 'framer-motion';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import BalanceCard from '../components/BalanceCard';
import PriceChart from '../components/PriceChart';
import TransactionList from '../components/TransactionList';
import { DonateCard } from '../components/DonateCard';
import { AddressDisplay } from '../components/AddressDisplay';
import { useAuth } from '../contexts/AuthContext';

export default function Dashboard() {
  const { walletInfo, currentUser, loading } = useAuth();

  // Show loading state if we're loading and there's a logged in user
  if (loading && currentUser) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold text-white">Portfolio</h1>
        <Card className="bg-guap-dark text-white p-6">
          <div className="text-center">
            <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-guap-yellow mb-4"></div>
            <div className="text-gray-400">
              Please be patient, your wallet is loading...
            </div>
          </div>
        </Card>
      </div>
    );
  }

  // Only show "No wallet available" if we're not loading and there's no wallet
  if (!loading && !walletInfo?.address) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold text-white">Portfolio</h1>
        <Card className="bg-guap-dark text-white p-6">
          <div className="text-center text-gray-400">
            No wallet available. Please create or recover a wallet.
          </div>
        </Card>
      </div>
    );
  }

  // At this point, we know walletInfo is not null because we checked above
  if (!walletInfo) {
    return null; // TypeScript guard
  }

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-white">Portfolio</h1>
        <div className="flex gap-4">
          <Button
            asChild
            variant="outline"
            className="bg-guap-dark text-white border-guap-yellow"
          >
            <a
              href="https://www.probit.com/en-us/app/exchange/GUAP-BTC"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy on ProBit
            </a>
          </Button>
          <Button
            asChild
            variant="outline"
            className="bg-guap-dark text-white border-guap-yellow"
          >
            <a href="https://www.guapswap.com" target="_blank" rel="noopener noreferrer">
              GuapSwap
            </a>
          </Button>
        </div>
      </div>

      <AddressDisplay address={walletInfo.address} />
      <BalanceCard address={walletInfo.address} coin="GUAP" />
      <DonateCard />
      <PriceChart coin="GUAP" />
      <TransactionList />
    </div>
  );
}
