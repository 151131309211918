import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from './components/ui/toaster';
import { AuthProvider } from './contexts/AuthContext';
import { CreateWallet, Dashboard, FAQ, Login, Receive, Send, Settings } from './pages';
import ImportWallet from './pages/ImportWallet';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/create-wallet" element={<PrivateRoute><CreateWallet /></PrivateRoute>} />
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/send" element={<PrivateRoute><Send /></PrivateRoute>} />
              <Route path="/receive" element={<PrivateRoute><Receive /></PrivateRoute>} />
              <Route path="/import-wallet" element={<PrivateRoute><ImportWallet /></PrivateRoute>} />
              <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
              <Route path="/faq" element={<PrivateRoute><FAQ /></PrivateRoute>} />
            </Route>
          </Routes>
        </Router>
        <Toaster />
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
