import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Loader2 } from 'lucide-react';
import { useToast } from '../hooks/use-toast';
import { useAuth } from '../contexts/AuthContext';
import { sendTransaction } from '../lib/api';

const sendFormSchema = z.object({
  toAddress: z.string().min(26).max(42),
  amount: z.string().refine((val) => !isNaN(Number(val)) && Number(val) > 0, {
    message: "Amount must be a positive number",
  }),
});

type SendFormData = z.infer<typeof sendFormSchema>;

export default function Send() {
  const [isProcessing, setIsProcessing] = useState(false);
  const { toast } = useToast();
  const { walletInfo, currentUser } = useAuth();
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SendFormData>({
    resolver: zodResolver(sendFormSchema),
  });

  if (!walletInfo?.address) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold text-white">Send</h1>
        <Card className="bg-guap-dark text-white p-6">
          <div className="text-center text-gray-400">
            No wallet available. Please create or recover a wallet.
          </div>
        </Card>
      </div>
    );
  }

  const onSubmit = async (data: SendFormData) => {
    try {
      if (!currentUser?.uid) {
        toast({
          title: "Authentication Required",
          description: "Please log in to send transactions.",
          variant: "destructive",
        });
        return;
      }

      setIsProcessing(true);

      const result = await sendTransaction(
        currentUser.uid,
        walletInfo.address,
        data.toAddress,
        Number(data.amount)
      );

      toast({
        title: "Transaction Sent Successfully",
        description: `Transaction ID: ${result.txid}`,
      });

      reset();
    } catch (error: any) {
      console.error('Transaction error:', error);
      toast({
        title: "Transaction Failed",
        description: error.response?.data?.error || error.message || "Failed to send transaction. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-white">Send GUAP</h1>
      <Card className="bg-guap-dark text-white p-6">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="space-y-2">
            <Label>From Address</Label>
            <div className="p-3 bg-black/20 border border-guap-yellow rounded-md">
              {walletInfo.address}
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="toAddress">To Address</Label>
            <Input
              id="toAddress"
              placeholder="Enter GUAP address"
              {...register('toAddress')}
              className="bg-black/20 border-guap-yellow"
              disabled={isProcessing}
            />
            {errors.toAddress && (
              <p className="text-red-500 text-sm">{errors.toAddress.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <Label htmlFor="amount">Amount</Label>
            <div className="relative">
              <Input
                id="amount"
                placeholder="0.00000000"
                {...register('amount')}
                className="bg-black/20 border-guap-yellow pr-16"
                disabled={isProcessing}
              />
              <span className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-gray-400">
                GUAP
              </span>
            </div>
            {errors.amount && (
              <p className="text-red-500 text-sm">{errors.amount.message}</p>
            )}
          </div>

          <Button
            type="submit"
            className="w-full bg-gradient-gold text-black hover:opacity-90"
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Sending...
              </>
            ) : (
              'Send GUAP'
            )}
          </Button>
        </form>
      </Card>
    </div>
  );
}
