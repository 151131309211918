import { useState } from 'react';
import { motion } from 'framer-motion';
import { Mail } from 'lucide-react';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Card } from '../../components/ui/card';
import { useToast } from '../../hooks/use-toast';
import { auth } from '../../lib/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

interface ResetPasswordFormProps {
  onBack: () => void;
}

export function ResetPasswordForm({ onBack }: ResetPasswordFormProps) {
  const { toast } = useToast();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Reset Email Sent",
        description: "Check your email for password reset instructions.",
      });
      onBack();
    } catch (error: any) {
      toast({
        title: "Reset Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4 relative">
      <div className="fixed inset-0 bg-[url('/bg-waves.png')] bg-cover bg-center opacity-20" />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md relative z-20"
      >
        <Card className="p-8 bg-gradient-dark-gold animate-gradient rounded-2xl">
          <div className="flex flex-col items-center space-y-6">
            <img 
              src="https://guapexplorer.com/ico/android-icon-192x192.png" 
              alt="GUAP" 
              className="w-20 h-20"
            />
            <h1 className="text-3xl font-bold text-gradient-gold-white">
              Reset Password
            </h1>

            <form onSubmit={handleResetPassword} className="w-full space-y-4">
              <div className="space-y-2">
                <div className="relative">
                  <Mail className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    className="pl-10 bg-[#232323] border-guap-yellow text-white rounded-xl"
                    required
                  />
                </div>
              </div>

              <Button
                type="submit"
                className="w-full bg-gradient-gold text-black hover:opacity-90 rounded-xl font-medium"
                disabled={isLoading}
              >
                Send Reset Link
              </Button>

              <Button
                type="button"
                variant="ghost"
                className="w-full text-white/80 hover:text-white rounded-xl"
                onClick={onBack}
              >
                Back to Login
              </Button>
            </form>
          </div>
        </Card>
      </motion.div>
    </div>
  );
}
