import { createContext, useContext, useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { createDemoWallet } from '../lib/wallet';
import { getWalletData } from '../lib/api';
import { getFirebaseAuth } from '../lib/firebase-lazy';

interface AdditionalAddress {
  address: string;
  path: string;
  index: number;
}

interface WalletInfo {
  address: string;
  mnemonic?: string;
  coin?: 'GUAP' | 'BTC';
  additionalAddresses?: AdditionalAddress[];
}

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  bypassAuth: boolean;
  setBypassAuth: (bypass: boolean) => void;
  walletInfo: WalletInfo | null;
  setWalletInfo: (info: WalletInfo | null) => void;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
  bypassAuth: false,
  setBypassAuth: () => {},
  walletInfo: null,
  setWalletInfo: () => {},
});

// Named export for useAuth hook
const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [bypassAuth, setBypassAuth] = useState(false);
  const [walletInfo, setWalletInfoState] = useState<WalletInfo | null>(null);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);

  // Handle demo wallet creation when bypass auth is enabled
  useEffect(() => {
    const setupDemoWallet = async () => {
      if (bypassAuth && !walletInfo) {
        try {
          const demoWallet = await createDemoWallet('GUAP');
          setWalletInfoState({
            ...demoWallet,
            additionalAddresses: []
          });
        } catch (error) {
          console.error('Error creating demo wallet:', error);
        }
      }
    };

    setupDemoWallet();
  }, [bypassAuth]);

  // Initialize Firebase Auth lazily
  useEffect(() => {
    const initAuth = async () => {
      try {
        const auth = await getFirebaseAuth();
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          setCurrentUser(user);
          
          if (user && !bypassAuth) {
            try {
              setWalletLoading(true);
              const walletData = await getWalletData(user.uid);
              if (walletData) {
                setWalletInfoState({
                  address: walletData.address,
                  mnemonic: walletData.mnemonic,
                  coin: walletData.coin,
                  additionalAddresses: []
                });
              } else {
                // Reset wallet info if no data found
                setWalletInfoState(null);
              }
            } catch (error) {
              console.error('Error loading wallet data:', error);
              // Reset wallet info on error to prevent stale data
              setWalletInfoState(null);
            } finally {
              setWalletLoading(false);
            }
          } else if (!bypassAuth) {
            // Reset wallet info when user logs out
            setWalletInfoState(null);
          }
          
          setLoading(false);
        });

        setAuthInitialized(true);
        return unsubscribe;
      } catch (error) {
        console.error('Error initializing Firebase Auth:', error);
        setLoading(false);
      }
    };

    if (!authInitialized) {
      initAuth();
    }
  }, [authInitialized, bypassAuth]);

  const setWalletInfo = async (info: WalletInfo | null) => {
    if (!info) {
      setWalletInfoState(null);
      return;
    }

    try {
      setWalletInfoState(info);
    } catch (error) {
      console.error('Error saving wallet info:', error);
      throw new Error('Failed to save wallet information');
    }
  };

  const value = {
    currentUser,
    loading: loading || walletLoading, // Combine both loading states
    bypassAuth,
    setBypassAuth,
    walletInfo,
    setWalletInfo
  };

  // Only render children once Firebase Auth is initialized
  return (
    <AuthContext.Provider value={value}>
      {(!loading || authInitialized) && children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth };
