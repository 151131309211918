import { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Card } from '../components/ui/card';
import { Button } from '../components/ui/button';
import AddressSelect from '../components/AddressSelect';
import { Copy } from 'lucide-react';
import { useToast } from '../hooks/use-toast';
import { useAuth } from '../contexts/AuthContext';

const EXPLORER_URL = 'https://ex1.guapcoin.org/address/';

export default function Receive() {
  const [selectedAddress, setSelectedAddress] = useState('');
  const { toast } = useToast();
  const { walletInfo } = useAuth();

  const copyAddress = async () => {
    const addressToCopy = selectedAddress || walletInfo?.address;
    if (!addressToCopy) return;
    
    await navigator.clipboard.writeText(addressToCopy);
    toast({
      title: "Address Copied",
      description: "The address has been copied to your clipboard.",
    });
  };

  if (!walletInfo?.address) {
    return (
      <div className="space-y-8">
        <h1 className="text-3xl font-bold text-white">Receive</h1>
        <Card className="bg-guap-dark text-white p-6">
          <div className="text-center text-gray-400">
            No wallet address available. Please create or recover a wallet.
          </div>
        </Card>
      </div>
    );
  }

  const addresses = [
    { address: walletInfo.address, label: 'Primary Address' },
    ...(walletInfo.additionalAddresses || []).map(addr => ({
      address: addr.address,
      label: `Address #${addr.index}`
    }))
  ];

  const currentAddress = selectedAddress || walletInfo.address;

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold text-white">Receive</h1>
      <Card className="bg-guap-dark text-white p-6">
        <div className="space-y-6">
          <div className="flex flex-col items-center space-y-6">
            <div className="w-full space-y-2">
              <label className="text-sm text-gray-400">Select Address</label>
              <AddressSelect
                addresses={addresses}
                selectedAddress={currentAddress}
                onAddressChange={setSelectedAddress}
              />
            </div>

            <div className="p-4 bg-white rounded-lg">
              <QRCodeSVG
                value={currentAddress}
                size={200}
                level="H"
              />
            </div>

            <div className="w-full space-y-2">
              <p className="text-sm text-gray-400">Your GUAP Address</p>
              <div className="flex items-center gap-2">
                <code className="flex-1 p-3 bg-black/20 rounded-lg font-mono text-sm break-all">
                  {currentAddress}
                </code>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={copyAddress}
                  className="border-guap-yellow"
                >
                  <Copy className="w-4 h-4" />
                </Button>
              </div>
              <a
                href={`${EXPLORER_URL}${currentAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-guap-yellow hover:underline"
              >
                View on Explorer
              </a>
            </div>

            <div className="w-full p-4 bg-black/20 rounded-lg text-center">
              <p className="text-sm text-gray-400">
                Send only GUAP to this address. Sending any other coins may result in permanent loss.
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
