import { motion } from 'framer-motion';
import { Card } from '@/components/ui/card';

interface WalletLayoutProps {
  children: React.ReactNode;
}

export default function WalletLayout({ children }: WalletLayoutProps) {
  return (
    <div className="min-h-screen bg-black flex items-center justify-center p-4 relative">
      <div className="absolute inset-0 bg-[url('/bg-waves.png')] bg-cover bg-center opacity-20" />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md relative z-10"
      >
        <Card className="p-8 bg-gradient-dark-gold">
          {children}
        </Card>
      </motion.div>
    </div>
  );
}